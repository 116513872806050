export const replaceunderscore = (status) => {
  let statusJoin = status?.split("_").join(" ") || "";
  let statusCap = statusJoin.charAt(0).toUpperCase() + statusJoin.slice(1);

  return statusCap;
};

export const contentmodify = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length > 8) {
    texts = texts.split("").slice(0, 11).join("") + "...";
  }
  return texts;
};
export const rejectmsg = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length >= 10) {
    texts = texts.split("").slice(0, 9).join("") + "...";
  }
  return texts;
};
export const paidbylen = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length >= 15) {
    // texts = texts.split("").slice(0, 15).join("") + "...";
    texts = texts.split("").slice(0, 15).join("");
  }
  return texts;
};

export const emaillen = (msg) => {
  let texts = "";
  if (msg.length >= 10) {
    texts = msg.split("").slice(0, 10).join("") + "...";
  }
  return texts;
};

export const organizationlen = (msg, maxLen = 16) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length >= maxLen) {
    texts = texts.split("").slice(0, maxLen).join("") + "...";
  }
  return texts;
};
export const approvedbylen = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length > 12) {
    texts = texts.split("").slice(0, 12).join("") + "...";
  }
  return texts;
};

export const rejectedbylen = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length >= 16) {
    texts = texts.split("").slice(0, 16).join("") + "...";
  }
  return texts;
};

export const descriptionlen = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length > 12) {
    texts = texts.split("").slice(0, 12).join("") + "...";
  }
  return texts;
};

export const reasonlen = (msg) => {
  let texts = msg.charAt(0).toUpperCase() + msg.slice(1);
  if (texts.length > 12) {
    texts = texts.split("").slice(0, 12).join("") + "...";
  }
  return texts;
};

export const invoiceNumberLen = (number = "") => {
  let texts = "";
  if (number.length >= 15) {
    texts = number.split("").slice(0, 8).join("") + "...";
  }
  return texts;
};

export const first4Capitalise = (msg) => {
  if (msg !== undefined) {
    let newPan = msg?.slice(0, 4).toUpperCase() + msg?.slice(4, 11);

    return newPan;
  }
};

export const UserName = (fullname) => {
  let name = fullname ? fullname.split(" ") : null;
  if (name) {
    // name =name[0].charAt(0).toUpperCase() + name[0].slice(1) + " " + name[1].charAt(0).toUpperCase() + name[1].slice(1)
    name = name
      .map((n, i) => {
        return n.charAt(0).toUpperCase() + n.slice(1);
      })
      .join(" ");
    return name;
  } else {
    return fullname;
  }
};

export const filesplitting = (name) => {
  let filename = name.split("/")[5];
  return filename;
};
export const gstsplitting = (name) => {
  let filename = name?.slice(name?.lastIndexOf("/") + 1);
  if (!filename) {
    return "Upload Gst Certificate";
  } else {
    return filename;
  }
};
export const filepath = (name) => {
  if (name !== null) {
    let file_name = name.split("/").splice(3).join("/");
    return file_name;
  }
};

export const handleArray = (arr) => {
  let arr2 = [];
  if (arr != null) {
    for (let i = 0; i < arr.length; i++) {
      let x = arr[i].split("/").splice(3).join("/");
      arr2.push(x);
    }
    return arr2;
  }
};

export const convertToString = (str) => {
  return str.toString();
};
