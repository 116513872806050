import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  replaceunderscore,
  contentmodify,
  UserName,
  paidbylen,
  descriptionlen,
  convertToString,
  invoiceNumberLen,
} from "../middleware/statusModify";
import Pagination from "../Pagination";
import moment from "moment";
import Navbar from "../navbar/navbar";
import SideBar from "../sideBar/adminsidebars";
// import Adminstatus from "./adminstatus";
// import Adminamount from "./adminamount";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import {
  getFinancerAdminAmountAction,
  getFinancerAdminStatusAction,
} from "../../action/adminStatusAction";
// import AdminCount from "./adminCount";
import "./admin.css";
import { getAdminOverviewAction } from "../../action/adminAction";
import { getProfileAction } from "../../action/userAction";
import ViewInvoice from "../modal/viewInvoice";

import "../../../src/App.css";

export default function InvoiceTrails(props) {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const { getAdmin } = useSelector((state) => state.admin);

  const initialstate = {
    id: "",
    name: "",
    email: "",
    mobileNumber: "",
    invoice: "",
    date: "",
    due_date: "",
    amount: "",
    filename: "",
  };

  const [viewInvoice, setViewInvoice] = useState(initialstate);
  // const partialPaid=

  // const { getafStatus } = useSelector((state) => state.statusaf);
  // const { getafAmount } = useSelector((state) => state.amountaf);

  const [arrow, setArrow] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("");

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const pageVisited = pageNumber * perpage;
  const pageCount = Math.ceil(
    getAdmin && getAdmin.invoice && getAdmin.invoice.count / perpage
  );

  useEffect(() => {
    dispatch(getFinancerAdminStatusAction());
    dispatch(getFinancerAdminAmountAction());
    dispatch(getProfileAction());
    dispatch(
      getAdminOverviewAction(
        search,
        sortBy,
        sortOrder,
        pageNumber === 0 ? 1 : pageVisited + 1,
        search.length === 0
          ? pageVisited + JSON.parse(perpage)
          : getAdmin && getAdmin.invoice && getAdmin.invoice.count
      )
    );
  }, [search, pageVisited, perpage, sortBy, sortOrder]);

  const [mobileOpen, setMobileOpen] = useState(true);
  const [alag, setAlag] = useState("partistes");
  const [sidestyle, setSidestyle] = useState("sideopen");
  const [viewopen, setViewopen] = useState(false);
  const [invoiceDetailsId, setInvoiceDetailsId] = useState();

  const viewClose = () => {
    setViewopen(false);
    setInvoiceDetailsId();
  };

  const handleSort = (name) => {
    setArrow(name);
    if (name === "date") {
      setSortBy("date");
      if (sortOrder === "" || sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder("asc");
      }
    }
  };

  const toggle = () => {
    if (mobileOpen === true) {
      setMobileOpen(false);
      setAlag("bigpartistes");
      setSidestyle("sideopen");
    } else {
      setMobileOpen(true);
      setAlag("partistes");
      setSidestyle("sideclose");
    }
  };

  const data = "admin";

  // const handleView = (data) => {
  //   setViewopen(true);
  //   setViewInvoice({
  //     id: data.id,
  //     name: data?.users?.name,
  //     email: data?.users?.email,
  //     mobileNumber: data?.users?.mobileNumber,
  //     invoice: data?.invoiceno,
  //     date: data?.date,
  //     due_date: data?.due_date,
  //     amount: new Intl.NumberFormat("en-IN").format(data.amount),
  //     status: data.status,
  //     filename: data.fileName || data.file_name,
  //     docs: data.documents,
  //     approved: data.approved,
  //     paid: data.paid,
  //   });
  // };
  return (
    <>
      <Navbar
        mobileOpen={mobileOpen}
        toggle={toggle}
        name={props.location.state ? props.location.state.name : "Invoices"}
      />
      <SideBar
        mobileOpen={mobileOpen}
        stylesname={sidestyle}
        toggle={toggle}
        name={props.location.state ? props.location.state.name : "Invoices"}
      />
      <TableContainer className={alag}>
        {/* <Adminstatus
          listStatus={getafStatus && getafStatus.invoice}
          admin={data}
        /> */}
        {/* <Adminamount amountStatus={getafAmount && getafAmount.invoice} />
        <AdminCount /> */}

        <div className="invoice-bottom-table22 widthss">
          <div className="mx-2 mt-3 boxborder">
            <Row>
              <Col className="titlecol">
                <span className="table-title">Invoices</span>

                <div className="border-0 pt-1">
                  <hr className="mt-1 bottom_borderColor_overview"></hr>
                </div>
              </Col>
            </Row>

            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Invoice Number</th>
                    <th onClick={() => handleSort("date")}>
                      Date
                      {sortOrder == "asc" || sortOrder == "" ? (
                        <ArrowUpwardOutlinedIcon />
                      ) : arrow == "date" ? (
                        <ArrowDownwardOutlinedIcon />
                      ) : (
                        <ArrowUpwardOutlinedIcon />
                      )}
                    </th>
                    <th>Description</th>
                    <th> Created By</th>
                    <th> Approved By</th>
                    <th>Amount</th>
                    <th>Total Amount</th>
                    <th>Partial Amount</th>
                    <th>Pending Amount</th>
                    <th>Fully Paid</th>
                    <th> Rejected By</th>
                    <th> Paid By</th>
                    <th> Status</th>
                    <th>Overdue Days</th>
                  </tr>
                </thead>
                <tbody>
                  {getAdmin &&
                  getAdmin?.invoice?.rows &&
                  getAdmin?.invoice?.rows?.length > 0 ? (
                    getAdmin?.invoice?.rows?.map((row, i) => (
                      <tr key={i}>
                        <td className="align_left">
                          <span
                            onClick={() => {
                              setInvoiceDetailsId(row?.invoice_no);
                              setViewopen(true);
                            }}
                            className="pageLink"
                          >
                            {row?.invoice_no}
                          </span>
                        </td>

                        {/* <td className="align_left pl-3">
                          {row?.original_invoice_no
                            ? convertToString(
                                row?.original_invoice_no
                              ).toUpperCase()
                            : "--"}
                        </td> */}

                        <td className="align_left">
                          {row?.original_invoice_no ? (
                            (row?.original_invoice_no).length <= 15 ? (
                              convertToString(
                                row?.original_invoice_no
                              ).toUpperCase()
                            ) : (
                              <OverlayTrigger
                                placement="bottom"
                                interactive={true}
                                overlay={
                                  <Tooltip
                                    id={`tooltip`}
                                    className="tooltip-desc"
                                  >
                                    {convertToString(
                                      row?.original_invoice_no
                                    ).toUpperCase()}
                                  </Tooltip>
                                }
                              >
                                <span className="descriptionBox">
                                  {invoiceNumberLen(
                                    convertToString(
                                      row?.original_invoice_no
                                    ).toUpperCase()
                                  )}
                                </span>
                              </OverlayTrigger>
                            )
                          ) : (
                            "--"
                          )}
                        </td>

                        <td className="align_left">
                          {moment(row?.date).format("DD MMM YYYY")}
                        </td>

                        <td className="align_left">
                          {row?.description &&
                          row?.description?.length <= 12 ? (
                            descriptionlen(row?.description)
                          ) : (
                            <OverlayTrigger
                              placement="bottom"
                              interactive={true}
                              delay={
                                row?.description?.length <= 90 &&
                                row?.description?.length > 12
                                  ? ""
                                  : { hide: 2200, show: 300 }
                              }
                              overlay={
                                <Tooltip
                                  id={`tooltip`}
                                  className="tooltip-desc"
                                >
                                  <div className="toolTip">
                                    {row?.description || "-"}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span className="descriptionBox">
                                {row?.description
                                  ? descriptionlen(row?.description)
                                  : "-"}
                              </span>
                            </OverlayTrigger>
                          )}
                        </td>
                        <td className="align_left">
                          {row?.created ? row?.created?.name : "-"}
                        </td>
                        {/* <td className="align_left">
                          {row.approved && row.approved.name
                            ? contentmodify(row.approved.name)
                            : "-"}
                        </td> */}

                        <td className="align_left">
                          <>
                            {row?.approved &&
                            row?.approved?.name?.length < 12 ? (
                              UserName(row?.approved?.name)
                            ) : row?.approved && row?.approved?.name ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={`tooltip`}
                                    className="tooltip-desc"
                                  >
                                    <div className="toolTip">
                                      {UserName(row?.approved?.name)}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="descriptionBox">
                                  {UserName(
                                    row?.approved?.name.slice(0, 12) + "..."
                                  )}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              "-"
                            )}
                          </>
                        </td>
                        <td className="align_left">{row?.amount}</td>
                        <td className="align_left pl-3">{row?.totalAmount}</td>
                        <td className="align_left pl-3">
                          {row.status === "rejected"
                            ? "-"
                            : +row?.pending_amount === 0 ||
                              row?.pending_amount === row?.totalAmount
                            ? "-"
                            : row?.totalAmount - row?.pending_amount}
                        </td>

                        <td className="align_left pl-3">
                          {row.status === "rejected"
                            ? "-"
                            : +row?.pending_amount === 0
                            ? "-"
                            : row?.pending_amount}
                        </td>
                        <td className="align_left pl-3">
                          {row.status === "rejected"
                            ? "-"
                            : +row?.pending_amount === 0
                            ? row.totalAmount
                            : "-"}
                        </td>

                        {/* <td className="align_left">
                          {row.rejected && row.rejected.name
                            ? contentmodify(row.rejected.name)
                            : "-"}
                        </td> */}

                        <td className="align_left pl-3">
                          <>
                            {row?.rejected &&
                            row?.rejected?.name?.length < 12 ? (
                              row?.rejected && row?.rejected?.name ? (
                                UserName(row?.rejected?.name)
                              ) : (
                                "-"
                              )
                            ) : row?.rejected && row?.rejected?.name ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={`tooltip`}
                                    className="tooltip-desc"
                                  >
                                    <div className="toolTip">
                                      {UserName(row?.rejected?.name)}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="descriptionBox">
                                  {UserName(
                                    row?.rejected?.name.slice(0, 12) + "..."
                                  )}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              "-"
                            )}
                          </>
                        </td>

                        {/* <td className="align_left">
                          {row.paid && row.paid.name
                            ? contentmodify(row.paid.name)
                            : "-"}
                        </td> */}

                        <td className="align_left">
                          <>
                            {row?.paid && row?.paid?.name?.length < 12 ? (
                              row?.paid && row?.paid?.name ? (
                                paidbylen(row?.paid?.name)
                              ) : (
                                "-"
                              )
                            ) : row?.paid && row?.paid?.name ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id={`tooltip`}
                                    className="tooltip-desc"
                                  >
                                    <div className="toolTip">
                                      {paidbylen(row?.paid?.name)}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="descriptionBox">
                                  {paidbylen(
                                    row?.paid?.name.slice(0, 11) + "..."
                                  )}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              "-"
                            )}
                          </>
                        </td>

                        {/* <td className="align_left">
                          {replaceunderscore(row.status)}
                        </td> */}

                        {row.status === "rejected" ? (
                          <td
                            className={`align_left leftmr ${
                              row.status === "rejected" ? "red" : ""
                            }`}
                          >
                            {row?.rejection_msg?.length > 0 ? (
                              <OverlayTrigger
                                placement="bottom"
                                delay={
                                  row?.rejection_msg?.length <= 75 &&
                                  row?.rejection_msg?.length >= 0
                                    ? ""
                                    : { hide: 2200, show: 300 }
                                }
                                overlay={
                                  <Tooltip
                                    id={`tooltip`}
                                    className="tooltip-desc"
                                  >
                                    <div className="toolTip">
                                      {row.rejection_msg && row.rejection_msg}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span>{replaceunderscore(row.status)}</span>
                              </OverlayTrigger>
                            ) : (
                              <>
                                <span>{replaceunderscore(row.status)}</span>
                              </>
                            )}
                          </td>
                        ) : row?.status === "partial_payment" ? (
                          <td
                            className={`align_left leftmr${
                              row?.status === "partial_payment" ? "red" : ""
                            }`}
                          >
                            {row?.reason?.length > 0 ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip`}>
                                    <div className="toolTip">
                                      {row?.reason && row?.reason}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span>{replaceunderscore(row.status)}</span>
                              </OverlayTrigger>
                            ) : (
                              <span>{replaceunderscore(row.status)}</span>
                            )}
                          </td>
                        ) : (
                          <td className="align_left leftmr">
                            {replaceunderscore(row.status)}
                          </td>
                        )}

                        <td
                          className={
                            row.overDue <= 14
                              ? "range1 align_left"
                              : row.overDue >= 15 && row.overDue <= 25
                              ? "range2 align_left"
                              : row.overDue >= 26 && row.overDue <= 30
                              ? "range3 align_left"
                              : "align_left"
                          }
                        >
                          {row.overDue || row.overDue == 0 ? row.overDue : "-"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="notFound">
                      <div className="noUserfound">No Data Found</div>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pageContainer">
              <Pagination
                count={pageCount}
                setPerpage={setPerpage}
                setPageNumber={setPageNumber}
                perpage={perpage}
              />
            </div>
          </div>
        </div>
      </TableContainer>
      {/* {viewopen ? (
        <ViewInvoice
          open={viewopen}
          close={viewClose}
          invoiceDetailsId={invoiceDetailsId}
        />
      ) : (
        ""
      )} */}
      {viewopen && (
        <ViewInvoice
          open={viewopen}
          close={viewClose}
          invoiceDetailsId={invoiceDetailsId}
        />
      )}
    </>
  );
}
