import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { Row, Col, OverlayTrigger, ModalBody } from "react-bootstrap";
import { useSelector } from "react-redux";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import {
  emaillen,
  organizationlen,
  descriptionlen,
  replaceunderscore,
} from "../middleware/statusModify";
import "./addUser.css";
import "../adminDashboard/admin.css";
import { getInvoiceDetailsListAction } from "../../action/adminAction";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import moment from "moment";
import PartialPaymentModal from "../adminDashboard/PartialPaymentModal";
import { addCommasToNumber } from "../../utility";

export default function ViewInvoice({ open, close, invoiceDetailsId }) {
  const dispatch = useDispatch();
  const [partialDetailsModal, setPartialDetailsModal] = useState(false);
  const invoiceDetailsList = useSelector((state) => state?.invoiceDetailsList);
  const details = invoiceDetailsList?.invoiceDetailsList?.data?.invoice;
  const handleModalOpen = () => {
    setPartialDetailsModal(true);
  };
  const handleModalClose = () => {
    setPartialDetailsModal(false);
  };
  // const dateFormat = (date) => {
  //   var newDate = new Date(date);
  //   return newDate.toLocaleString("en-GB")?.split(",")[0];
  // };
  var url = details && details?.file_name;
  const downloadInvoice = (url, imgname) => {
    saveAs(url, imgname);
  };
  useEffect(() => {
    dispatch(getInvoiceDetailsListAction(invoiceDetailsId));
  }, []);
  return (
    <>
      <Modal open={open} onClose={close}>
        <Box className="modalclass30 modalViewBox">
          <div className="div-add-user-button">
            <div onClick={close} className="modalcrossbutton">
              X
            </div>
          </div>
          <div className="mx-2 modalboxborder">
            <div className="table-responsive">
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Sr. No.</th>
                    <th>Invoice No</th>
                    <th>Email ID</th>
                    <th>Mobile No.</th>
                    <th>Organization</th>
                    <th>Description</th>
                    <th>Supporting Document</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align_left">
                      {invoiceDetailsId && details?.users?.name
                        ? details?.users?.name
                        : "--"}
                    </td>
                    <td>
                      {invoiceDetailsId && details?.invoice_no
                        ? details?.invoice_no 
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.original_invoice_no ? (
                        details?.original_invoice_no.length <= 10 ? (
                          details?.original_invoice_no
                        ) : (
                          <Tooltip
                            className="tooltip-desc"
                            title={
                              <div className="toolTip">
                                {details?.original_invoice_no.toUpperCase() || "-"}
                              </div>
                            }
                          >
                            <span className="descriptionBox">
                              {details?.original_invoice_no &&
                                emaillen(details?.original_invoice_no).toUpperCase()}
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.users?.email ? (
                        details?.users?.email.length <= 10 ? (
                          emaillen(details?.users?.email)
                        ) : (
                          <Tooltip
                            className="tooltip-desc"
                            title={
                              <div className="toolTip">
                                {details?.users?.email || "-"}
                              </div>
                            }
                          >
                            <span className="descriptionBox">
                              {details?.users?.email &&
                                emaillen(details?.users?.email)}
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.users?.mobile_number
                        ? details?.users?.mobile_number
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId &&
                      details?.users?.organization?.organization ? (
                        details?.users?.organization?.organization.length <=
                        10 ? (
                          organizationlen(
                            details?.users?.organization?.organization
                          )
                        ) : (
                          <Tooltip
                            className="tooltip-desc"
                            title={
                              <div className="toolTip">
                                {details.users?.organization?.organization}
                              </div>
                            }
                          >
                            <span className="descriptionBox">
                              {details?.users?.organization?.organization &&
                                organizationlen(
                                  details?.users?.organization?.organization
                                )}
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="align_left">
                      {/* {details?.description} */}
                      {invoiceDetailsId && details?.description ? (
                        details?.description.length <= 10 ? (
                          descriptionlen(details?.description)
                        ) : (
                          <Tooltip
                            className="tooltip-desc"
                            title={
                              <div className="toolTip">
                                {details?.description}
                              </div>
                            }
                          >
                            <span className="descriptionBox">
                              {details?.description &&
                                descriptionlen(details?.description)}
                            </span>
                          </Tooltip>
                        )
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.documents?.length > 0 ? (
                        details?.documents ? (
                          details?.documents?.map((data, index) => (
                            <li>
                              <div className="labelname">
                                <span
                                  className="documents"
                                  onClick={() =>
                                    downloadInvoice(
                                      data,
                                      `Document${index + 1}`
                                    )
                                  }
                                >
                                  Document{index + 1}
                                </span>
                              </div>
                            </li>
                          ))
                        ) : (
                          <Col>
                            <div className="labelname">No Document Found</div>
                          </Col>
                        )
                      ) : (
                        "--"
                      )}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.file_name ? (
                        <div className="icons inconsdiv">
                          <div className="downviewicon">
                            <DownloadForOfflineOutlinedIcon
                              className="downloadIcon"
                              onClick={() =>
                                downloadInvoice(
                                  details?.file_name && details?.file_name,
                                  details?.invoice_no
                                )
                              }
                            />
                            <div style={{ color: "#4BCBF0" }}>save</div>
                          </div>
                          <div className="downviewicon">
                            <a href={url} target="_blank">
                              <RemoveRedEyeOutlinedIcon className="view" />
                            </a>
                            <div style={{ color: "#4BCBF0" }}>view</div>
                          </div>
                        </div>
                      ) : (
                        "--"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <caption className="modal-caption">Invoice Trail</caption>
          <div className="mx-2 modalboxborder">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>GST Amount(&#8377;)</th>
                    <th>TDS Amount(&#8377;)</th>
                    <th>Total Amount(&#8377;)</th>
                    <th>Created By</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align_left">
                      {invoiceDetailsId && details?.status
                        ? replaceunderscore(details?.status)
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.amount
                        ? addCommasToNumber(details?.amount)
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.GST_Amount
                        ? addCommasToNumber(details?.GST_Amount)
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.TDS_Amount
                        ? addCommasToNumber(details?.TDS_Amount)
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.totalAmount
                        ? addCommasToNumber(details?.totalAmount)
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.created?.name
                        ? details?.created?.name
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.createdAt
                        ? moment(details?.createdAt).format("DD MMM YYYY")
                        : "--"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mx-2 modalboxborder">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Approved By</th>
                    <th>Date</th>
                    {/* <th>Partial Pymt Details</th> */}
                    <th>Full Payment</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align_left">
                      {invoiceDetailsId && details?.approved?.name
                        ? details?.approved?.name
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.approvedDate
                        ? moment(details?.approvedDate).format("DD MMM YYYY")
                        : "--"}
                    </td>
                    {/* <td className="align_left">
                      <center className="leftmr">
                        <RemoveRedEyeOutlinedIcon
                          className="viewIcon"
                          onClick={handleModalOpen}
                        />
                      </center>
                    </td> */}
                    {/* <td className="align_left">
                    {/* <td className="align_left">
                      <center className="leftmr">
                        <RemoveRedEyeOutlinedIcon
                          className="viewIcon"
                          onClick={handleModalOpen}
                        />
                      </center>
                    </td> */}
                    {/* <td className="align_left">
                      {invoiceDetailsId && details?.result[0]?.partialPaidBy
                        ? details?.result[0]?.partialPaidBy
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.result[0]?.updatedAt
                        ? dateFormat(details?.result[0]?.updatedAt)
                        : "--"}
                    </td> */}
                    {/* <td className="align_left">
                      {invoiceDetailsId && details?.result[0]?.fullPaidBy
                        ? details?.result[0]?.fullPaidBy
                        : "--"}
                    </td> */}
                    <td className="align_left">
                      {invoiceDetailsId && details?.fullPaymentDate
                        ? details?.paid?.name
                        : "--"}
                    </td>
                    <td className="align_left">
                      {invoiceDetailsId && details?.fullPaymentDate
                        ? moment(details?.fullPaymentDate).format("DD MMM YYYY")
                        : "--"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div>Partial Payment Details</div> */}
          <caption className="modal-caption">Partial Payment Details</caption>
          <div className="mx-2 modalboxborder">
            <div className="table-responsive">
              <table className="table  table-hover">
                <thead>
                  <th>Paid by Name</th>
                  <th>Organization</th>
                  <th>Paid Amount</th>
                  <th>Pending Amount</th>
                  <th>Reason</th>
                  <th>Date</th>
                </thead>
                <tbody>
                  {details?.audit_trails &&
                    details?.audit_trails?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {data?.partialPaidBy ? data?.partialPaidBy : "--"}
                        </td>
                        <td className="align_left">
                          {invoiceDetailsId &&
                          details?.users?.organization?.organization ? (
                            details?.users?.organization?.organization.length <=
                            10 ? (
                              organizationlen(
                                details?.users?.organization?.organization
                              )
                            ) : (
                              <Tooltip
                                className="tooltip-desc"
                                title={
                                  <div className="toolTip">
                                    {details.users?.organization?.organization}
                                  </div>
                                }
                              >
                                <span className="descriptionBox">
                                  {details?.users?.organization?.organization &&
                                    organizationlen(
                                      details?.users?.organization?.organization
                                    )}
                                </span>
                              </Tooltip>
                            )
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {data?.amount_paid
                            ? addCommasToNumber(data?.amount_paid)
                            : data?.totalPaidAmount
                            ? addCommasToNumber(data?.totalPaidAmount)
                            : "--"}
                        </td>
                        <td>
                          {data?.totalPaidAmount
                            ? details?.totalAmount - data?.totalPaidAmount
                            : "--"}
                        </td>
                        <td>{data?.reason ? data?.reason : "--"}</td>
                        <td>
                          {data?.updatedAt
                            ? moment(data?.updatedAt).format("DD MMM YYYY")
                            : "--"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="topviewrow">
          <div className="title">
            <span className="modal-title">Invoice Details</span>
            <div className="border-0 pt-2">
              <hr className="mt-1 bottom_borderColor_overview"></hr>
            </div>
          </div>
          <div>
            <div className="icons inconsdiv">
              <div className="downviewicon">
                <DownloadForOfflineOutlinedIcon
                
                  className="downloadIcon"
                  onClick={() =>
                    downloadInvoice(
                      viewdata && viewdata.filename,
                      viewdata.invoice
                    )
                  }
                />
                <div style={{ paddingTop: "1px", color: "#4BCBF0" }}>save</div>
              </div>
              <div className="downviewicon">
                <a rel="noreferrer" href={url} target="_blank">
                  <RemoveRedEyeOutlinedIcon className="view" />
                </a>
                <div style={{ paddingTop: "1px", color: "#4BCBF0" }}>view</div>
              </div>
            </div>
          </div>
        </div>
        <Row className="viewRow">
          <Col lg={5}>
            <span className="viewName">Vendor Name</span>
            <div className="labelname">
              {viewdata?.name
                ? viewdata.name.charAt(0).toUpperCase() + viewdata.name.slice(1)
                : null}
            </div>
          </Col>
          <Col lg={7}>
            <span className="viewName"> Email ID</span>
            <div className="labelname">{viewdata?.email || "-"}</div>
          </Col>
        </Row>
        <Row className="viewRow">
          <Col lg={5}>
            <span className="viewName"> Mobile No.</span>
            <div className="labelname">{viewdata?.mobileNumber || "-"}</div>
          </Col>
          <Col lg={7}>
            <span className="viewName"> Invoice</span>
            <div className="labelname">{viewdata?.invoice || "-"}</div>
          </Col>
        </Row>
        <Row className="viewRow lastrow">
          <Col lg={5}>
            <span className="viewName"> Status</span>
            <div className="labelname">
              {viewdata?.status ? replaceunderscore(viewdata.status) : null}
            </div>
          </Col>
          <Col lg={7}>
            <span className="viewName"> Amount</span>
            <div className="labelname">{viewdata.amount || "-"}</div>
          </Col>
        </Row>
        <span className="supprottxt">Supporting Documents</span>
        <Row className="admindoc">
          {viewdata?.docs?.length > 0 && Array.isArray(viewdata?.docs) ? (
            viewdata?.docs?.map((data, index) => (
              <Col>
                <div className="labelname">
                  <span
                    className="documents"
                    onClick={() =>
                      downloadInvoice(data, `Document${index + 1}`)
                    }
                  >
                    Document{index + 1}
                  </span>
                </div>
              </Col>
            ))
          ) : (
            <Col>
              <div className="labelname">No Document Found</div>
            </Col>
          )}
        </Row>


        <Row className="viewRow lastrow">
          <Col lg={5}>
            <span className="viewName"> Approved By</span>
            <div className="labelname">

            {viewdata?.approved?.name
                ? viewdata?.approved?.name?.charAt(0).toUpperCase() + viewdata?.approved?.name?.slice(1):
                
                viewdata?.approved?
                viewdata?.approved?.charAt(0).toUpperCase() + viewdata?.approved?.slice(1)
                
                :"-"}

            </div>
          </Col>
          <Col lg={5}>
            <span className="viewName"> 
            Paid By</span>
            <div className="labelname">
            {viewdata?.paid
                ? viewdata?.paid?.name.charAt(0).toUpperCase() + viewdata.paid?.name.slice(1)
                : "-"}
            </div>
          </Col>
        </Row>
        <Row className="viewRow">
          <Col className="text-center">
            <button className="viewCncl-btn" onClick={close}>
              Cancel
            </button>
          </Col>
        </Row>*/}
        </Box>
      </Modal>
      {/* {partialDetailsModal && (
        <Modal open={handleModalOpen} onClose={handleModalClose}>
          <Box className="modalclass30 modalViewBox">
            <div className="div-add-user-button">
              <div onClick={handleModalClose} className="modalcrossbutton">
                X
              </div>
            </div>
            <div>Partial Payment Details</div>
            <div className="mx-2 modalboxborder">
              <div className="table-responsive">
                <table className="table  table-hover">
                  <thead>
                    <th>Name</th>
                    <th>Paid Amount</th>
                    <th>Pending Amount</th>
                    <th>Date</th>
                  </thead>
                  <tbody>
                    {details?.result &&
                      details?.result?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {data.partialPaidBy ? data.partialPaidBy : "--"}
                          </td>
                          <td>{data.amount_paid ? data.amount_paid : "--"}</td>
                          <td>
                            {data.totalPaidAmount
                              ? details.invoice.totalAmount -
                                data.totalPaidAmount
                              : "--"}
                          </td>
                          <td>
                            {data.updatedAt
                              ? moment(data.updatedAt).format("DD MMM YYYY")
                              : "--"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Modal>
      )} */}
      {/* {partialDetailsModal && (
        <Modal open={handleModalOpen} onClose={handleModalClose}>
          <Box className="modalclass30 modalViewBox">
            <div className="div-add-user-button">
              <div onClick={handleModalClose} className="modalcrossbutton">
                X
              </div>
            </div>
            <div>Partial Payment Details</div>
            <div className="mx-2 modalboxborder">
              <div className="table-responsive">
                <table className="table  table-hover">
                  <thead>
                    <th>Name</th>
                    <th>Paid Amount</th>
                    <th>Pending Amount</th>
                    <th>Date</th>
                  </thead>
                  <tbody>
                    {details?.result &&
                      details?.result?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {data.partialPaidBy ? data.partialPaidBy : "--"}
                          </td>
                          <td>{data.amount_paid ? data.amount_paid : "--"}</td>
                          <td>
                            {data.totalPaidAmount
                              ? details.invoice.totalAmount -
                                data.totalPaidAmount
                              : "--"}
                          </td>
                          <td>
                            {data.updatedAt
                              ? moment(data.updatedAt).format("DD MMM YYYY")
                              : "--"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Modal>
      )} */}
    </>
  );
}
