// export const BASE_URL = "http://25e9-2401-4900-1c21-b002-80e4-afdd-2b3a-e293.ngrok.io"; //for localhost
// export const BASE_URL = "http://5.189.191.68:5501"; //for test
export const BASE_URL = "https://invoiceuat.nimapinfotech.com"; //for test

// export const BASE_URL = "https://invoice.nimapinfotech.com";  //for production

//User
export const SIGNIN = "/api/user/signin";
export const GOGGLESIGNIN = "/api/user/google-login";
export const PROFILE = "/api/user/profile";
export const UPROFILE = "/api/user/profile/updated";
export const FORGOT_PASS = "/api/user/forgot-password-otp";
export const VALIDATE_OTP = "/api/user/verify-otp";
export const UPDATE_PASSWORD = "/api/user/update-password";
export const ADD_USER = "/api/admin/add/user";
export const EDIT_USER = "/api/admin/user";

//Admin Panel.
export const TOTAL_COUNT = "/api/admin/vendor/invoice-count";
export const REVIEW_COUNT = "/api/admin/review-pending/invoice";
export const PENDING_COUNT = "/api/admin/pending-payment/count";
export const REJECT_COUNT = "/api/admin/total/rejected";
export const PAID_COUNT = "/api/admin/paid/invoice";

export const ADMIN_USER = "/api/admin/roles/users";
export const ADMIN_ROLE = "/api/admin/role";
export const VENDOR = "/api/admin/vendor";
export const CREATE_INVOICE = "/api/invoice/admin/invoice/createInvoice";
export const EMAILS = "/api/admin/vendor-email-list";
export const VIEW_VENDOR = "/api/admin/vendor/dashboard";
export const VIEW_VENDORUSER = "/api/admin/vendor/user";
export const VIEW_VENDOR_LIST = "/api/admin/vendor-invoice";
export const INV_LIST = "/api/admin/get-invoice-list";
export const PAID_LIST = "/api/admin/get-paid-invoices";
export const PARTIAL_LIST = "/api/admin/invoices/partialPaid";
// export const PARTIAL_LIST = "/api/admin/get-paid-invoices";

export const ADMIN_DASH_OVERVIEW = "/api/admin/overdue-list";
export const ADMIN_UPCOMING_OVERDUE = "/api/admin/upcoming-overdue-list";

export const ADD_VUSER = "/api/admin/add/vendoruser";
export const GET_EDIT_VUSER = "api/admin/vendor";
export const EDIT_VUSER = "/api/admin/vendorUser";
export const VIEW_VUSER = "/api/admin/vendor/user";
export const VUSER_LIST = "/api/admin/vendoruser/invoice";

export const APPROVER = "/api/admin/approver";
export const APP_LIST = "/api/admin/vendor/invoice";
export const ACCEPT_INVLIST = "/api/approver/approved/list";
export const REJECT_INVLIST = "/api/approver/rejected/list";
export const ACCEPT_REJECT = "/api/admin/approver/status";
export const APPROVE_ID = "/api/approver/vendor/approver";
export const APPROVER_EDIT = "/api/approver/vendor";

export const FINANCER = "/api/admin/financer";
export const PAID_REJECT = "/api/admin/financer/status";
export const PAID_REJ = "/api/financer/rejected";
export const OVERDUE = "/api/admin/get-overDue-invoices";
export const ARCHIVED = "/api/admin/get-archived-invoice-list";
export const ARCHIVED_BULK_PAYMENT = "/api/archievedbulkpayments";

export const CHANGE_PASS = "/api/user/changepassword";

export const ADMIN = "/api/admin/dashboard/invoices/count";
export const ADMIN_INV = "/api/admin/invoices";

//admin crud
export const ADMINNEW = "/api/admin/";

//Vendor Status
export const VENDOR_COUNT = "/api/vendor/get-invoice-count";
export const VENDOR_AMT = "/api/vendor/total-amount";
export const APPROVER_COUNT = "/api/approver/count";
export const APPROVER_AMT = "/api/approver/amount";
export const FINADMIN_COUNT = "/api/admin/total-count";
export const FINADMIN_AMT = "/api/admin/total-amount";

//admin status
export const VENDOR_TOTAL = "/api/admin/vendor/count";
export const APPROVER_TOTAL = "/api/admin/approver/count";
export const FINANCER_TOTAL = "/api/admin/financer/count";
export const ADMIN_PAID = "/api/admin/financer/status";

//Vendor Panel
export const VEN_DASH = "/api/vendor/dashboard";
export const VEN_VUSER = "/api/vendor/vendoruser";
export const VENUSER_LIST = "/api/vendor/get-vendor-user-list";
export const CREATE_INV = "/api/invoice";
export const INV_DATA = "/api/vendor/invoice-list";
export const VUSER_INV = "/api/vendor/vendoruser/invoice-list";
export const DEL_INV = "/api/vendor/deleteinvoiceByUser";
export const ADD_IMG = "/api/invoice/images";
export const GST = "/api/invoice/gst/list";
export const TDS = "/api/invoice/tds/list";
export const UPDATEINV = "/api/invoice/invoice-details";
export const REGISTER = "/api/vendor/vendor-request";
export const VENDOR_REQ = "/api/vendor/get-vendor-request";
export const VENDOR_ACCEPT = "/api/vendor/approve-vendor-request";
export const VENDOR_REJECT = "/api/vendor/reject-vendor-request";

//Financer Panel
export const FINANCER_DASH = "/api/financer/dashboard";
export const FINANCER_INV = "/api/financer/get-invoice-list-financer"; //
export const FINANCER_PAID = "/api/financer/get-paid-invoices";
export const FINANCER_STATUS = "/api/admin/financer/status";
export const FINANCER_DASH_OVERVIEW = "/api/financer/overdue-list";
export const FINANCER_OVERDUE_LIST = "/api/financer/upcoming-overdue-list";

export const GET_ARCHIVE = "/api/financer/get-archived-invoice-list";
export const ARCHIVE = "/api/financer/archive-invoice";
export const UNARCHIVE = "/api/financer/unarchive-invoice";

export const APP_FINANCER_PAY_PENDING_LIST = "/api/financer/vendor-invoice";
export const APP_FINANCER_PAY_PAID_LIST = "/api/financer/vendor-invoice";
export const APP_FINANCER_PAY_PARTIAL_PAID_LIST =
  "/api/financer/vendor-invoice";
export const APP_FINANCER_PAY_REJECTED_LIST = "/api/financer/vendor-invoice";
export const BULK_PAYMENT_STATUS = "/api/financer/bulk-payment-status";

//Approver Panel
export const APP_DASH = "/api/approver/dashboard";
export const APP_DASH_OVERVIEW = "/api/approver/overdue-list";
export const INVFORAPP = "/api/approver/invoice/vendor";
export const APP_STATUS = "/api/approver/status";
export const APP_OVERDUE_LIST = "/api/approver/upcoming-overdue-list";
export const GETPERMISSION = "/api/admin/read-roles-permissions";
export const PERMISSION = "/api/admin/roles-permissions";

export const APP_PENDING_LIST = "/api/approver/vendor-invoice";
export const APP_PAID_LIST = "/api/approver/vendor-invoice";
export const APP_APPROVED_LIST = "/api/approver/vendor-invoice";
export const APP_REJECTED_LIST = "/api/approver/vendor-invoice";

export const GET_VENDORREQUEST_LIST = "/api/approver/get-vendor-request";

// BankData

export const GET_BANK_DATA = "/api/financer/getallbanks";
export const ADD_BANK_DATA = "/api/financer/addbank";
export const DELETE_BANK_DATA = "/api/financer/deletebank";
export const UPDATE_BANK_DATA = "/api/financer/updatebank";
