import { toast } from "react-toastify";
import * as roleType from "../constant/adminConstant/roleConstant";
import * as venType from "../constant/adminConstant/vendorConstant";
import * as appTypes from "../constant/adminConstant/approverConstant";
import * as financeType from "../constant/adminConstant/financerConstant";
import * as userType from "../constant/adminConstant/userConstant";
import * as countType from "../constant/adminConstant/adminCount";
import * as mngType from "../constant/adminConstant/manageuser";
import * as adminType from "../constant/adminConstant/adminConstant";

import {
  EDIT_VUSER,
  PAID_COUNT,
  PENDING_COUNT,
  REJECT_COUNT,
  REVIEW_COUNT,
  TOTAL_COUNT,
  VIEW_VENDOR,
  ADD_VUSER,
  VUSER_LIST,
  VENDOR,
  CREATE_INVOICE,
  APPROVER,
  FINANCER,
  CHANGE_PASS,
  VIEW_VENDOR_LIST,
  VIEW_VENDORUSER,
  APP_LIST,
  ACCEPT_INVLIST,
  REJECT_INVLIST,
  ACCEPT_REJECT,
  PAID_REJECT,
  APPROVE_ID,
  ADD_USER,
  INV_LIST,
  PAID_LIST,
  PARTIAL_LIST,
  PAID_REJ,
  ADMIN_ROLE,
  PERMISSION,
  OVERDUE,
  EMAILS,
  VENDOR_REJECT,
  VENDOR_ACCEPT,
  VENDOR_REQ,
  REGISTER,
  ARCHIVED,
  ARCHIVED_BULK_PAYMENT,
  ADMIN,
  ADMIN_INV,
  EDIT_USER,
  ADMIN_DASH_OVERVIEW,
  ADMIN_UPCOMING_OVERDUE,
  APPROVER_EDIT,
  ADMINNEW,
  GET_EDIT_VUSER,
} from "../resources/APIEndpoints";
import {
  delservice,
  getservice,
  postservice,
  putservice,
} from "../api/apiservices/service";
import axios from "axios";

//Count Admin
export const getCountAdminAction = () => async (dispatch) => {
  dispatch({ type: countType.COUNT_ADMIN_REQUEST });
  try {
    const response = await getservice(TOTAL_COUNT);
    dispatch({ type: countType.COUNT_ADMIN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: countType.COUNT_ADMIN_FAILED, payload: error });
  }
};

export const getInvoiceDetailsListAction = (id) => async (dispatch) => {
  dispatch({ type: userType.GET_INVOICE_DETAILS_LIST_REQUEST });
  try {
    const response = await getservice(`/api/admin/audit-trails/${id}`);
    dispatch({
      type: userType.GET_INVOICE_DETAILS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: userType.GET_INVOICE_DETAILS_LIST_FAILED,
      payload: error,
    });
  }
};

// Overview dashboard
export const getAdminOverviewAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: userType.GET_ADMIN_REQUEST });
    try {
      const response = await getservice(
        ADMIN_DASH_OVERVIEW +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: userType.GET_ADMIN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: userType.GET_ADMIN_FAILED, payload: error });
    }
  };

// Upcoming Overdue
export const getAdminUpcomingOverduection =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: userType.GET_ADMIN_REQUEST });
    try {
      const response = await getservice(
        ADMIN_UPCOMING_OVERDUE +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: userType.GET_ADMIN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: userType.GET_ADMIN_FAILED, payload: error });
    }
  };

//Archived Bulk Payment
export const getAdminArchivedBulkPayment =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: userType.GET_ADMIN_REQUEST });
    try {
      const response = await getservice(
        ARCHIVED_BULK_PAYMENT +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: userType.GET_ADMIN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: userType.GET_ADMIN_FAILED, payload: error });
    }
  };

//pending Payment
export const getPendingPayAdminAction = () => async (dispatch) => {
  dispatch({ type: countType.PENDINGPAY_ADMIN_REQUEST });
  try {
    const response = await getservice(PENDING_COUNT);
    dispatch({
      type: countType.PENDINGPAY_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: countType.PENDINGPAY_ADMIN_FAILED, payload: error });
  }
};

//Review Pending
export const getReviewPenAdminAction = () => async (dispatch) => {
  dispatch({ type: countType.REVIEWPEN_ADMIN_REQUEST });
  try {
    const response = await getservice(REVIEW_COUNT);
    dispatch({
      type: countType.REVIEWPEN_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: countType.REVIEWPEN_ADMIN_FAILED, payload: error });
  }
};

//Reject Count
export const getRejCountAdminAction = () => async (dispatch) => {
  dispatch({ type: countType.REJECTCOUNT_ADMIN_REQUEST });
  try {
    const response = await getservice(REJECT_COUNT);
    dispatch({
      type: countType.REJECTCOUNT_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: countType.REJECTCOUNT_ADMIN_FAILED, payload: error });
  }
};

//Paid Count
export const getPaidCountAdminAction = () => async (dispatch) => {
  dispatch({ type: countType.PAIDCOUNT_ADMIN_REQUEST });
  try {
    const response = await getservice(PAID_COUNT);
    dispatch({
      type: countType.PAIDCOUNT_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: countType.PAIDCOUNT_ADMIN_FAILED, payload: error });
  }
};

//Get Vendor
export const getVendorAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_VENDOR_REQUEST });
    try {
      const response = await getservice(
        VIEW_VENDOR + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: venType.GET_VENDOR_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: venType.GET_VENDOR_FAILED, payload: error });
    }
  };

// Get vendor list page
export const getVendorListAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_VENDORMLIST_REQUEST });
    try {
      const response = await getservice(
        VIEW_VENDOR_LIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: venType.GET_VENDORMLIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: venType.GET_VENDORMLIST_FAILED, payload: error });
    }
  };

//Get approver id in vendor modal
export const getapproveridtAction = (id) => async (dispatch) => {
  dispatch({ type: venType.GET_APPID_REQUEST });
  try {
    const response = await getservice(APPROVE_ID + `/${id}`);
    dispatch({ type: venType.GET_APPID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: venType.GET_APPID_FAILED, payload: error });
  }
};

//Admin add vendor user
export const addAdminvuserAction = (details, close) => async (dispatch) => {
  dispatch({ type: venType.ADD_ADMINVUSER_REQUEST });
  try {
    const response = await postservice(ADD_VUSER, details);
    dispatch({ type: venType.ADD_ADMINVUSER_SUCCESS, payload: response.data });
    toast.success("Vendor user Added");
    close();
  } catch (error) {
    dispatch({ type: venType.ADD_ADMINVUSER_FAILED });
  }
};

//Admin update vendor user
export const editAdminvuserAction = (id, user) => async (dispatch) => {
  dispatch({ type: venType.EDIT_ADMINVUSER_REQUEST });
  try {
    const response = await putservice(EDIT_VUSER + `/${id}`, user);
    dispatch({ type: venType.EDIT_ADMINVUSER_SUCCESS, payload: response.data });
    toast.success("Vendor user updated");
  } catch (error) {
    dispatch({ type: venType.EDIT_ADMINVUSER_FAILED });
  }
};

//View vendor user
export const getadminvendorUserAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_ADMINVUSER_REQUEST });
    try {
      const response = await getservice(
        VIEW_VENDORUSER + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: venType.GET_ADMINVUSER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: venType.GET_ADMINVUSER_FAILED });
    }
  };

//Get vendor user modal
export const getvendorUserListAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_VENDORUMLIST_REQUEST });
    try {
      const response = await getservice(
        VUSER_LIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: venType.GET_VENDORUMLIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: venType.GET_VENDORUMLIST_FAILED });
    }
  };

//add Role
export const addRoleAction = (roledet) => async (dispatch) => {
  dispatch({ type: roleType.ADD_ROLE_REQUEST });
  try {
    const response = await postservice(ADD_USER, roledet);
    dispatch({ type: roleType.ADD_ROLE_SUCCESS, payload: response.data });
    toast.success("User Added");
  } catch (error) {
    dispatch({ type: roleType.ADD_ROLE_FAILED, payload: error });
  }
};

//Get Role user list
export const getRoleAction = () => async (dispatch) => {
  dispatch({ type: roleType.GET_ROLE_REQUEST });
  try {
    const response = await getservice("/api/admin/get-roles/usrs");
    dispatch({ type: roleType.GET_ROLE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: roleType.GET_ROLE_FAILED, payload: error });
  }
};

//Update Role
export const editRoleAction = (roledet, close) => async (dispatch) => {
  dispatch({ type: roleType.EDIT_ROLE_REQUEST });
  try {
    const response = await putservice(
      `/api/admin/role/change/user/${roledet.id}`,
      roledet
    );
    dispatch({ type: roleType.EDIT_ROLE_SUCCESS, payload: response.data });
    toast.success("Role Updated");
    close();
  } catch (error) {
    dispatch({ type: roleType.EDIT_ROLE_FAILED, payload: error });
  }
};

//Delete Role
export const deleteRoleAction = (id, close) => async (dispatch) => {
  dispatch({ type: roleType.DELETE_ROLE_REQUEST });
  try {
    const response = await delservice(`/api/admin/role/delete/${id}`);

    dispatch({ type: roleType.DELETE_ROLE_SUCCESS, payload: response.data });
    toast.success("Role Deleted");
    close();
  } catch (error) {
    dispatch({ type: roleType.DELETE_ROLE_FAILED, payload: error });
  }
};

//Add Vendor
export const addVendorAction = (vendorInfo, onclose) => async (dispatch) => {
  dispatch({ type: venType.ADD_VENDOR_REQUEST });
  try {
    const response = await postservice(VENDOR, vendorInfo);

    dispatch({ type: venType.ADD_VENDOR_SUCCESS, payload: response.data });
    toast.success("Vendor Added");
    onclose();
  } catch (error) {
    dispatch({ type: venType.ADD_VENDOR_FAILED, payload: error });
  }
};

export const getEditVendorAction = (id) => async (dispatch) => {
  dispatch({ type: venType.GET_EDIT_VENDOR_REQUEST });
  try {
    const response = await getservice(GET_EDIT_VUSER + `/${id}`);
    dispatch({ type: venType.GET_EDIT_VENDOR_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: venType.GET_EDIT_VENDOR_FAILED, payload: error });
  }
};

// Update Vendor
export const editVendorAction =
  (vendorInfo, onclose, role) => async (dispatch) => {
    dispatch({ type: venType.EDIT_VENDOR_REQUEST });
    try {
      const url = role === "approver" ? APPROVER_EDIT : VENDOR;
      const response = await putservice(url + `/${vendorInfo.id}`, vendorInfo);
      dispatch({ type: venType.EDIT_VENDOR_SUCCESS, payload: response.data });
      toast.success("Vendor Updated Successfully");
      onclose();
    } catch (error) {
      dispatch({ type: venType.EDIT_VENDOR_FAILED, payload: error });
    }
  };

//Delete Vendor
export const deleteVendorAction = (id) => async (dispatch) => {
  dispatch({ type: venType.DELETE_VENDOR_REQUEST });
  try {
    const response = await putservice(`/api/admin/delete/vendor/${id}`);
    dispatch({ type: venType.DELETE_VENDOR_SUCCESS, payload: response.data });
    toast.success("Vendor Deleted");
  } catch (error) {
    dispatch({ type: venType.DELETE_VENDOR_FAILED, payload: error });
  }
};

//Create admin side invoice
export const addAdminInvAction =
  (approverdet, history, setIsSubmit, setIsDisable) => async (dispatch) => {
    dispatch({ type: venType.CREATE_ADDINV_REQUEST });
    try {
      const response = await postservice(CREATE_INVOICE, approverdet);
      dispatch({ type: venType.CREATE_ADDINV_SUCCESS, payload: response.data });
      history.push("/admin/vendor");
      toast.success("Invoice added");
      onclose();
    } catch (error) {
      dispatch({ type: venType.CREATE_ADDINV_FAILED, payload: error });
      setIsSubmit(false);
      setIsDisable(false);
    }
  };

// Add Approver
export const addApproverAction = (approverdet, onclose) => async (dispatch) => {
  dispatch({ type: appTypes.ADD_APPROVER_REQUEST });
  try {
    const response = await postservice(APPROVER, approverdet);
    dispatch({ type: appTypes.ADD_APPROVER_SUCCESS, payload: response.data });
    toast.success("Approver Added Successfully");
    onclose();
  } catch (error) {
    dispatch({ type: appTypes.ADD_APPROVER_FAILED, payload: error });
  }
};

// Update Approver
export const editApproverAction = (approverInfo, close) => async (dispatch) => {
  dispatch({ type: appTypes.EDIT_APPROVER_REQUEST });
  try {
    const response = await putservice(
      APPROVER + `/${approverInfo.id}`,
      approverInfo
    );
    dispatch({ type: appTypes.EDIT_APPROVER_SUCCESS, payload: response.data });
    toast.success("Approver Updated Successfully");
  } catch (error) {
    dispatch({ type: appTypes.EDIT_APPROVER_FAILED, payload: error });
  }
  close();
};

//Get Approver
export const getApproverAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: appTypes.GET_APPROVER_REQUEST });
    try {
      const response = await getservice(
        APPROVER + `/dashboard?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: appTypes.GET_APPROVER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: appTypes.GET_APPROVER_FAILED, payload: error });
    }
  };

// Get Approver vendor list page pagination count
export const getAppVendorListLength = (id) => async (dispatch) => {
  dispatch({ type: appTypes.GET_ACPINVLEN_REQUEST });
  try {
    const response = await getservice(APP_LIST + `/${id}`);
    dispatch({ type: appTypes.GET_ACPINVLEN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: appTypes.GET_ACPINVLEN_FAILED, payload: error });
  }
};

// Get Approver vendor list page
export const getAppVendorListAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: appTypes.GET_ACPINVOICE_REQUEST });
    try {
      const response = await getservice(
        APP_LIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: appTypes.GET_ACPINVOICE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: appTypes.GET_ACPINVOICE_FAILED, payload: error });
    }
  };
//Get Approver accept list
export const getacplistApproverAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: appTypes.GET_ACPAPPROVER_REQUEST });
    try {
      const response = await getservice(
        ACCEPT_INVLIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: appTypes.GET_ACPAPPROVER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: appTypes.GET_ACPAPPROVER_FAILED, payload: error });
    }
  };
//Get Approver reject list
export const getrejlistApproverAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: appTypes.GET_REJAPPROVER_REQUEST });
    try {
      const response = await getservice(
        REJECT_INVLIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: appTypes.GET_REJAPPROVER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: appTypes.GET_REJAPPROVER_FAILED, payload: error });
    }
  };

//Admin accept invoice
export const acceptInvApproverAction =
  (invoiceno, status, approverEmail) => async (dispatch) => {
    dispatch({ type: appTypes.ACCEPT_INV_REQUEST });
    try {
      const response = await putservice(ACCEPT_REJECT + `/${invoiceno}`, {
        status,
        approverEmail,
      });
      dispatch({ type: appTypes.ACCEPT_INV_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: appTypes.ACCEPT_INV_FAILED, payload: error });
    }
  };
//Admin reject invoice
export const rejectInvApproverAction =
  (invoiceno, status, approverEmail) => async (dispatch) => {
    dispatch({ type: appTypes.REJECT_INV_REQUEST });
    try {
      const response = await putservice(ACCEPT_REJECT + `/${invoiceno}`, {
        ...status,
        approverEmail,
      });
      dispatch({ type: appTypes.REJECT_INV_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: appTypes.REJECT_INV_FAILED, payload: error });
    }
  };

//Delete approver
export const deleteApproverAction = (id, close) => async (dispatch) => {
  dispatch({ type: appTypes.DELETE_APPROVER_REQUEST });
  try {
    const response = await putservice(`/api/admin/delete/approver/${id}`);
    dispatch({
      type: appTypes.DELETE_APPROVER_SUCCESS,
      payload: response.data,
    });
    toast.success("Approver Deleted Succesfully");
    close();
  } catch (error) {
    dispatch({ type: appTypes.DELETE_APPROVER_FAILED, payload: error });
  }
};

export const deleteApproverPanelAction = (id, close) => async (dispatch) => {
  dispatch({ type: appTypes.DELETE_APPROVER_PANEL_REQUEST });
  try {
    const response = await delservice(`/api/approver/vendor/${id}`);
    dispatch({
      type: appTypes.DELETE_APPROVER_PANEL_SUCCESS,
      payload: response.data,
    });
    toast.success("Vendor Deleted Successfully");
    close();
  } catch (error) {
    dispatch({ type: appTypes.DELETE_APPROVER_PANEL_FAILED, payload: error });
  }
};

//Get Financer
export const getFinancerAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_FINANCER_REQUEST });
    try {
      const response = await getservice(
        FINANCER + `/dashboard?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_FINANCER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: financeType.GET_FINANCER_FAILED, payload: error });
    }
  };
export const getallinvFinancerAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_ALLFINANCER_REQUEST });
    try {
      const response = await getservice(
        INV_LIST + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_ALLFINANCER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: financeType.GET_ALLFINANCER_FAILED, payload: error });
    }
  };

//Get paid invoice list in financer modal
export const getpaidinvFinancerAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_PAIDFINANCER_REQUEST });
    try {
      const response = await getservice(
        PAID_LIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_PAIDFINANCER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: financeType.GET_PAIDFINANCER_FAILED, payload: error });
    }
  };

//Get partial invoice list in financer modal
export const getpartialinvFinancerAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_PARTIALFINANCER_REQUEST });
    try {
      const response = await getservice(
        PARTIAL_LIST + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_PARTIALFINANCER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: financeType.GET_PARTIALFINANCER_FAILED,
        payload: error,
      });
    }
  };

//Get rejected invoice list in financer modal
export const getrejinvFinancerAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_REJFINANCER_REQUEST });
    try {
      const response = await getservice(
        PAID_REJ + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_REJFINANCER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: financeType.GET_REJFINANCER_FAILED, payload: error });
    }
  };

//Get overdue invoice list in financer modal
export const getoverdueFinancerAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: financeType.GET_OVERDUEADMIN_REQUEST });
    try {
      const response = await getservice(
        OVERDUE + `/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: financeType.GET_OVERDUEADMIN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: financeType.GET_OVERDUEADMIN_FAILED, payload: error });
    }
  };

// Update Admin
export const editAdminAction = (id, adminInfo) => async (dispatch) => {
  dispatch({ type: adminType.EDIT_ADMIN_REQUEST });
  try {
    // const res = await axios.patch(`${FINANCER}/${id}`, financerInfo);
    const response = await putservice(ADMINNEW + `/${id}`, adminInfo);
    dispatch({
      type: adminType.EDIT_ADMIN_SUCCESS,
      payload: response.data,
    });
    toast.success("Admin Updated");
  } catch (error) {
    dispatch({ type: adminType.EDIT_ADMIN_FAILED, payload: error });
  }
};

// Update Financer
export const editFinancerAction = (id, financerInfo) => async (dispatch) => {
  dispatch({ type: financeType.EDIT_FINANCER_REQUEST });
  try {
    // const res = await axios.patch(`${FINANCER}/${id}`, financerInfo);
    const response = await putservice(FINANCER + `/${id}`, financerInfo);
    dispatch({
      type: financeType.EDIT_FINANCER_SUCCESS,
      payload: response.data,
    });
    toast.success("Financer Updated");
  } catch (error) {
    dispatch({ type: financeType.EDIT_FINANCER_FAILED, payload: error });
  }
};

//Delete Financer
export const deleteFinancerAction = (id, close) => async (dispatch) => {
  dispatch({ type: financeType.DELETE_FINANCER_REQUEST });
  try {
    const response = await putservice(`/api/admin/delete/financer/${id}`);
    dispatch({
      type: financeType.DELETE_FINANCER_SUCCESS,
      payload: response.data,
    });
    toast.success("Financer Deleted");
    close();
  } catch (error) {
    dispatch({ type: financeType.DELETE_FINANCER_FAILED, payload: error });
  }
};

//Delete Admin
export const deleteAdminAction = (id, close) => async (dispatch) => {
  dispatch({ type: adminType.DELETE_ADMIN_REQUEST });
  try {
    // const response = await putservice(`/api/admin/delete/admin/${id}`);
    const response = await delservice(`/api/admin/${id}`);

    dispatch({
      type: adminType.DELETE_ADMIN_SUCCESS,
      payload: response.data,
    });
    toast.success("Admin Deleted");
    close();
  } catch (error) {
    dispatch({ type: adminType.DELETE_ADMIN_FAILED, payload: error });
  }
};
//invoice update Status paid
export const paidStatusAction =
  ({ invoiceno, status }) =>
  async (dispatch) => {
    dispatch({ type: financeType.PAID_STATUS_REQUEST });
    try {
      const response = await putservice(PAID_REJECT + `/${invoiceno}`, {
        status,
      });
      dispatch({
        type: financeType.PAID_STATUS_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: financeType.PAID_STATUS_FAILED, payload: error });
    }
  };
//Rejection
export const rejectStatusAction =
  (invoiceno, status, id) => async (dispatch) => {
    dispatch({ type: financeType.REJECT_STATUS_REQUEST });
    try {
      const response = await putservice(
        PAID_REJECT + `/${invoiceno}`,
        status
      );
      dispatch({
        type: financeType.REJECT_STATUS_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: financeType.REJECT_STATUS_FAILED, payload: error });
    }
  };

export const editUserAction = (userId, data, close) => async (dispatch) => {
  dispatch({ type: userType.EDIT_USER_REQUEST });
  try {
    const response = await putservice(EDIT_USER + `/${userId}`, data);
    dispatch({ type: userType.EDIT_USER_SUCCESS, payload: response.data });
    toast.success(response.data.message);
    close();
  } catch (error) {
    dispatch({ type: userType.EDIT_USER_FAILED, payload: error });
  }
};

// Get admin User

export const getUserAction = (a, b, c) => async (dispatch) => {
  dispatch({ type: userType.GET_USER_REQUEST });
  try {
    const response = await getservice(ADMIN_ROLE);
    dispatch({ type: userType.GET_USER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: userType.GET_USER_FAILED, payload: error });
  }
};

//Delete Vendor user
export const deleteUserAction = (id, close) => async (dispatch) => {
  dispatch({ type: userType.DELETE_USER_REQUEST });
  try {
    const response = await putservice(`/api/admin/delete/vendor-user/${id}`);
    dispatch({ type: userType.DELETE_USER_SUCCESS, payload: response.data });
    toast.success(response.data.message);
    close();
  } catch (error) {
    dispatch({ type: userType.DELETE_USER_FAILED, payload: error });
  }
};

//Get Organization
// export const getOrganizationAction = () => async (dispatch) => {
//     dispatch({ type: venType.GET_ORGANIZATION_REQUEST })
//     try {
//         const response = await getservice("/api/admin/organization")
//         dispatch({ type: venType.GET_ORGANIZATION_SUCCESS, payload: response.data })
//     }
//     catch (error) {
//         dispatch({ type: venType.GET_ORGANIZATION_FAILED, payload: error })
//     }
// }

//change password
export const addPasswordAction =
  (passdetails, passClose) => async (dispatch) => {
    dispatch({ type: userType.ADD_CHNGPASS_REQUEST });
    try {
      const response = await putservice(CHANGE_PASS, passdetails);
      dispatch({ type: userType.ADD_CHNGPASS_SUCCESS, payload: response.data });
      toast.success("Password changed successfully");
      passClose();
    } catch (error) {
      dispatch({ type: userType.ADD_CHNGPASS_FAILED, payload: error });
    }
  };

//get approver user in admin add vendor modal
export const getapproveuserAction = () => async (dispatch) => {
  dispatch({ type: venType.GET_APPUSER_REQUEST });
  try {
    const response = await getservice("/api/approver/approver/listId/");
    dispatch({ type: venType.GET_APPUSER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: venType.GET_APPUSER_FAILED, payload: error });
  }
};

//get approver user in admin add vendor modal
export const getveneuserAction = () => async (dispatch) => {
  dispatch({ type: appTypes.GET_VENUSER_REQUEST });
  try {
    const response = await getservice("/api/vendor/get-all-vendor");
    dispatch({ type: appTypes.GET_VENUSER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: appTypes.GET_VENUSER_FAILED, payload: error });
  }
};

//Roles and Permission

//Get roles
export const getRoleListAction = () => async (dispatch) => {
  dispatch({ type: roleType.GET_ROLELIST_REQUEST });
  try {
    const response = await getservice(`/api/admin/get-roles/usrs`);
    dispatch({ type: roleType.GET_ROLELIST_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: roleType.GET_ROLELIST_FAILED, payload: error });
  }
};

//Save Permission
export const addPermission = (data) => async (dispatch) => {
  dispatch({ type: mngType.ADD_PERMISSION_REQUEST });
  try {
    const response = await postservice(PERMISSION, data);
    dispatch({ type: mngType.ADD_PERMISSION_SUCCESS, payload: response.data });
    toast.success("Permission Updated");
  } catch (error) {
    dispatch({ type: mngType.ADD_PERMISSION_FAILED, payload: error });
  }
};

//Get vendor email id
export const getVendorEmail = () => async (dispatch) => {
  dispatch({ type: venType.GET_EMAILIDS_REQUEST });
  try {
    const response = await getservice(EMAILS);
    dispatch({ type: venType.GET_EMAILIDS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: venType.GET_EMAILIDS_FAILED, payload: error });
  }
};

//Vendor Register Form
export const vendorRegisterAction =
  ({ details, resetForm }) =>
  async (dispatch) => {
    dispatch({ type: venType.REG_VENDOR_REQUEST });
    try {
      const response = await postservice(REGISTER, details);
      dispatch({ type: venType.REG_VENDOR_SUCCESS, payload: response.data });
      toast.success(response.data.message);
      resetForm({ values: "" });
    } catch (error) {
      dispatch({ type: venType.REG_VENDOR_FAILED, payload: error });
    }
  };

//Vendor Request
export const vendorRequestAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_VENREQUEST_REQUEST });
    try {
      const response = await getservice(
        VENDOR_REQ + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: venType.GET_VENREQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: venType.GET_VENREQUEST_FAILED, payload: error });
    }
  };

//Vendor Accept
export const acceptVenRequestAction = (id, details) => async (dispatch) => {
  dispatch({ type: venType.ACCEPT_VENDOR_REQUEST });
  try {
    const response = await postservice(VENDOR_ACCEPT + `/${id}`, details);
    dispatch({ type: venType.ACCEPT_VENDOR_SUCCESS, payload: response.data });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: venType.ACCEPT_VENDOR_FAILED, payload: error });
  }
};

//Vendor Accept
export const rejectVenRequestAction =
  ({ id, organization }) =>
  async (dispatch) => {
    dispatch({ type: venType.REJECT_VENDOR_REQUEST });
    try {
      const response = await postservice(VENDOR_REJECT + `/${id}`, {
        organization,
      });
      dispatch({ type: venType.REJECT_VENDOR_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: venType.REJECT_VENDOR_FAILED, payload: error });
    }
  };

//Get admin archived list
export const getArchivedListAction =
  (search, from, to = "1", startDate, endDate) =>
  async (dispatch) => {
    dispatch({ type: venType.ARCHIVED_REQUEST });
    try {
      const response = await getservice(
        ARCHIVED +
          `?search=${search}&from=${from}&to=${to}&startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({ type: venType.ARCHIVED_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: venType.ARCHIVED_FAILED, payload: error });
    }
  };

//Admin List
export const adminListAction =
  (search = "") =>
  async (dispatch) => {
    dispatch({ type: countType.ADMINLIST_REQUEST });
    try {
      const response = await getservice(ADMIN + `?search=${search}`);
      dispatch({ type: countType.ADMINLIST_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: countType.ADMINLIST_FAILED, payload: error });
    }
  };

export const adminAcceptAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: countType.ADMINACCEPT_REQUEST });
    try {
      const response = await getservice(
        ADMIN_INV + `/approved/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: countType.ADMINACCEPT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: countType.ADMINACCEPT_FAILED, payload: error });
    }
  };

export const adminPaidAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: countType.ADMINPAID_REQUEST });
    try {
      const response = await getservice(
        ADMIN_INV + `/paid/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: countType.ADMINPAID_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: countType.ADMINPAID_FAILED, payload: error });
    }
  };

export const adminRejectAction =
  (id, search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: countType.ADMINREJECT_REQUEST });
    try {
      const response = await getservice(
        ADMIN_INV + `/rejected/${id}?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: countType.ADMINREJECT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: countType.ADMINREJECT_FAILED, payload: error });
    }
  };
